$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.bodyWrap {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100%;
    padding-bottom: 20px;
    overflow: auto;
}
.welcomeMessage {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    border-bottom: linear-gradient(#F30531, #313AB8);
    background-image: url("../../../../img/shape.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: contain;
    background-color: #141421;

    @include for-phone-only {
        display: none;
      }
      @include for-tablet-portrait-up {
        display: none;
      }
      @include for-tablet-landscape-up {
        display: none;
      }
      @include for-desktop-up {
        display: flex;
      }
      @include for-big-desktop-up {
        display: flex;
      }
}

.mobileWelcomeMessage {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    border-bottom: linear-gradient(#F30531, #313AB8);
    background-image: url("../../../../img/shape.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    background-color: #141421;
    text-align: center;

    @include for-phone-only {
        display: flex;
      }
      @include for-tablet-portrait-up {
        display: flex;
      }
      @include for-tablet-landscape-up {
        display: flex;
      }
      @include for-desktop-up {
        display: none;
      }
      @include for-big-desktop-up {
        display: none;
      }
}

.welcomeMessage .welcomeTitle {
    color: white;
    margin: 0;
}


.mobileWelcomeMessage .welcomeTitle {
    color: white;
    margin: 0;
    font-size: 25px;
}

.welcomeMessage p,
.mobileWelcomeMessage p {
    color: white;
}

.gradient {
    height: 5px;
    background: linear-gradient(to right,#F2CE00, #F30531, #313AB8);
}


.infoTitle {
    display: flex;
    padding: 10px 20px 15px 20px;
}

.benchamrkTitle {
    display: flex;
    padding: 00px 20px;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-evenly;
    gap: 20px;

    @include for-phone-only {
display: none;
      }
      @include for-tablet-portrait-up {
        display: none;
      }
      @include for-tablet-landscape-up {
        display: none;
      }
      @include for-desktop-up {
        display: flex;
      }
      @include for-big-desktop-up {
        display: flex;
      }
}

.mobileInfoContainer {
    display: block;
    padding: 0 20px;

    @include for-phone-only {
display: block;
      }
      @include for-tablet-portrait-up {
        display: block;
      }
      @include for-tablet-landscape-up {
        display: block;
      }
      @include for-desktop-up {
        display: none;
      }
      @include for-big-desktop-up {
        display: none;
      }

      .infoText {
        margin: 0 0 20px 0;
      }
}

.infoCard {
    background-image: url("../../../../img/shape_info.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: contain;
    background-color: #141421;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 200px;
    /* gap: 20px; */
    align-items: center;
    /* justify-content: space-between; */
    /* padding: 20px; */
    border-radius: 8px;
    flex: 1 1 auto;

    @include for-phone-only {
        width: 100%;
      }
      @include for-tablet-portrait-up {
        width: 100%;
      }
      @include for-tablet-landscape-up {
        width: 200px;
      }
      @include for-desktop-up {
        width: 200px;
      }
      @include for-big-desktop-up {
        width: 200px;
      }
}


.infoText {
    text-align: center;
    z-index: 100;
    padding: 0 20px 20px 20px;
    width: 100%;
}

.buttonContainer {
    display: flex;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
}

.startingButton:hover {
    cursor: pointer;
}

.buttonContainer div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141421;
    color: white;
    padding: 8px 10px;
    gap: 10px;
    border-radius: 8px;
}

.buttonContainer p {
    margin: 0;
}

.benchmarkResults {
    padding: 0 20px;
}

.statisticsContainer {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-evenly;
    gap: 20px;
}

.statisticsCard {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 200px;
    /* gap: 20px; */
    align-items: flex-start;
    /* justify-content: space-between; */
    /* padding: 20px; */
    border-radius: 8px;
    flex: 1 1 auto;
}

.contentWrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* justify-content: space-evenly; */
    padding: 20px;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
}

.content .label {
    width: 30%;
}

.bodyContent     {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-evenly;
    // height: 100%;
}

.date_link {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tableRow:hover {
cursor: pointer;
}