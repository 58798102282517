.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout .ant-layout-sider-trigger {
  background: #171833;
}


.main-layout .main-layout-content {
  background: var(--background) !important;
  height: auto !important;
}

.main-layout-content > div {
  height: 100%;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  /* height: 200px;
  padding: 48px; */
  overflow: hidden;
  /* text-align: center; */
  background: #E9ECF7;
  /* border: 1px solid #ebedf0; */
  border-radius: 2px;
}


ul .ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark .ant-menu-dark {
  background: rgb(20, 20, 33)!important;
}

a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e9ecf7!important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: linear-gradient(#130F21,#130F21) padding-box,linear-gradient(30deg,#313AB8 20%,#F30531 50%,rgb(242,206,0) ) border-box !important;
  border: 2px solid transparent !important;
}

.ant-select .css-dev-only-do-not-override-1igzvsu .ant-select-focused .ant-select-single .ant-select-show-arrow .ant-select-selector {
   box-shadow: none!important;
}

.ant-select-selection-item {
  white-space: break-spaces!important;
  word-break: break-word!important;
}
