.ant-btn-primary {
    box-shadow: none!important;
}

input:focus, .ant-select-selector {
    box-shadow: none!important;
}

#benchmark-layout .site-drawer-render-in-current-wrapper {
    height: calc(100vh - 64px);
    position: relative;
    overflow: auto;
    text-align: left;
    background: #ffffff;

    #benchmark-layout .ant-drawer.ant-drawer-left {
        max-width: 275px;
        width: 100%;

        #benchmark-layout .ant-drawer-content-wrapper {
            max-width: 275px;
            width: 100%;

            #benchmark-layout .ant-drawer-header {
                padding: 10px!important;
                border-bottom: 0!important;
            
                #benchmark-layout .ant-drawer-header-title {
                    flex-direction: row-reverse;
            
                    #benchmark-layout .ant-drawer-title {
                        font-size: 20px;
                        line-height: 1.57;
                    }
                    button {
                        margin-right: 0px!important;
                        padding: 0px!important;
                    }
                }
            }
            #benchmark-layout .ant-drawer-body {
                padding: 0px 10px!important;
            }
        }
    }
}

