$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.navbarContainer {
    height: 64px;
    background-color: #141421;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
    @include for-phone-only {
        display: flex;
      }
      @include for-tablet-portrait-up {
        display: flex;
      }
      @include for-tablet-landscape-up {
        display: flex;
      }
      @include for-desktop-up {
        display: none;
      }
      @include for-big-desktop-up {
        display: none;
      }
}

.menuContainer {
    padding: 0 10px;
}