$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.container {
    display: flex;
    width: 100%;
    height: 100%;
    // background: rgb(20, 20, 33);
    position: relative;
    background-image: url("../../../../img/benchmark_bg.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: cover;
    background-color: #130F21;


    >img {
        background-color: #130F21;
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 100%;

        &.custom {
            object-fit: cover;
        }
    };

    .contentContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5%;
        position: absolute;
        align-items: center;
        padding: 20px;
        @include for-phone-only {
            flex-direction: column;
        }
        @include for-tablet-portrait-up {
            flex-direction: column;
        }
        @include for-tablet-landscape-up {
            flex-direction: row;
        }
        @include for-desktop-up {
            flex-direction: row;
        }
        @include for-big-desktop-up {
            flex-direction: row;
        }

        // >div {
        //     position: relative;
        //     display: flex;
        //     justify-content: center;
        //     flex-direction: column;            
        // }
        

        .videoContainer {
           background-color: #171833;
           width: 60%;
           height: 60%;
           max-height: calc(100% - 64px);
           align-items: flex-end;
           position: relative;
           display: flex;
           justify-content: center;
           flex-direction: column; 
           @include for-phone-only {
            width: 70%;
            height: 60%;
        }
        @include for-tablet-portrait-up {
            width: 70%;
            height: 60%;
        }
        @include for-tablet-landscape-up {
            width: 60%;
            height: 60%;
            max-height: calc(100% - 64px);
        }
        @include for-desktop-up {
            width: 60%;
            height: 60%;
            max-height: calc(100% - 64px);
        }
        @include for-big-desktop-up {
            width: 60%;
            height: 60%;
            max-height: calc(100% - 64px);
        }

        .floorSelectContainer {
            height: 0px;
            position: relative;
            top: -40px;
            width: 100%;
        }
        }

        .scanBenContainer {
            width: 30%;
            height: 100%;
            justify-content: center;
            align-items: center;
            gap: 5%;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column; 
            @include for-phone-only {
                width: 70%;
                height: auto;
            }
            @include for-tablet-portrait-up {
                width: 70%;
                height: auto;
            }
            @include for-tablet-landscape-up {
                width: 30%;
                height: 100%;
            }
            @include for-desktop-up {
                width: 30%;
                height: 100%;
            }
            @include for-big-desktop-up {
                width: 30%;
                height: 100%;
            }
            >img {
                width: 100%;
            }
        }
    }
}

.slider {
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;

    >div {
        height: 100%;
    }
  }
  .slideItems {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    transition: all 300ms;
  }
  .item {
    max-width: 33.333333%;
    width: 100%;
    height: 100%;
    vertical-align: top;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
  }
  .indicators {
    display: flex;
    list-style: none;
    justify-content: center;
    position: absolute;
    bottom: 20px;

    margin: 0;
    padding: 0;
    width: 100%;
  }
  .indicators {

    li {
        height: 10px;
        width: 10px;
        background: #fff;
        border-radius: 50%;
        margin-right: 5px;
        cursor: pointer;
        opacity: .7;
        transition: all 300ms;       
    }
    li:hover {
        opacity: 1;
    }
    li.active {
        background: #000;
    }
  }

  .slideArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 50px;
    height: 50px;
    background: #00000080;
    transition: all 300ms;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-width: 0px;

    &:hover {
        background: #222;
        color: #f78;
    }
  }

  .slidePrev {
    left: 0;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff;
    }
  }

  .slideNext {
    right: 0;
    
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff;
    }
  }