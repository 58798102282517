$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.sidebarMenuWrap {
    /* height: 85%; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    position: relative;
    height: 80vh;
    /* top: 60px; */
}

.profileName {
    /* display: flex; */
    position: absolute;
    bottom: 0;
}

.profileName {
    /* display: flex; */
    align-items: center;
    margin-left: 20px;
    gap: 10px;
}

.profileAvatar {
    background-image: linear-gradient(54.25deg, #313AB8 16.28%, #313AB8 16.29%, #8C2179 58.31%, #F30531 85.25%);
    border: none;
}

.sectionTitle {
    width: 100%;
    font-size: 12px;
    padding: 9px 16px;
    color: rgba(255, 255, 255, 0.35)
}

.displaySidebar {
    @include for-phone-only {
      display: none;
    }
    @include for-tablet-portrait-up {
      display: none;
    }
    @include for-tablet-landscape-up {
      display: none;
    }
    @include for-desktop-up {
      display: block;
    }
    @include for-big-desktop-up {
      display: block;
    }
  }
