.bodyContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 20px;
  margin: 10px;
  height: calc(100% - 73px);
  overflow: auto;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabs {
  max-width: 100%;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.uploadItem a {
  color: rgb(49, 58, 184) !important;
}
