$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
@media (max-width: 599px) { @content; }
}
@mixin for-mid-desktop-only {
@media (min-width: 1200px) and (max-width: 1500px) { @content; }
}
@mixin for-big-desktop-only {
@media (min-width: 1501px) and (max-width: 1799px) { @content; }
}
@mixin for-tablet-portrait-up {
@media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
@media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
@media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
@media (min-width: 1800px) { @content; }
}

.nhsCardContainer {
    display: flex;      
    justify-content: space-between;  
    flex: 1 1;
    max-width: 33.333333%;
    gap: 10px;
    background-color: #313AB8;
    color: #fff;
    padding: 20px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);

    @include for-mid-desktop-only{
        gap: 6px;
        padding: 20px 10px;
    }

    @include for-phone-only {
        flex-direction: column!important;
        max-width: 100%;
        display: flex;
        gap: 10px;
        }
        @include for-tablet-portrait-up {
            max-width: 100%;
        }
        @include for-tablet-landscape-up {
            max-width: 100%;
        }
        @include for-desktop-up {
            max-width: 33.333333%;
        }
        @include for-big-desktop-up {
            max-width: 33.333333%;
        } 
    
    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.571;
        margin: 0;

        @include for-big-desktop-only {
            font-size: 16px;
        }

        @include for-mid-desktop-only {
            font-size: 12px;
        }
    }

    .cardPrice {
        font-size: 32px;
        font-weight: bold;

        @include for-big-desktop-only {
            font-size: 20px;
        }

        @include for-mid-desktop-only {
            font-size: 17px;
        }
    }
    .cardPeriod {
        font-size: 14px;

        @include for-big-desktop-only {
            font-size: 10px;
        }

        @include for-mid-desktop-only {
            font-size: 9px;
        }
    }
    .cardDescription {
        font-size: 14px;

        @include for-big-desktop-only {
            font-size: 10px;
        }

        @include for-mid-desktop-only {
            font-size: 9px;
        }
    }

    :hover {
        cursor: pointer;
    }       
}

.nhsCardContainerRow {
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1 1;

        @include for-phone-only {
            flex-direction: column;
        }
    }
}

.nhsCardContainerCol {
    flex-direction: column;
}


   

.cardBasicContainer {
    background-color: #313AB8;
    &.active {
        box-shadow: 0px 8px 15px 5px rgb(49 58 184 / 65%)
    }
}

.cardOptimalContainer {
    background-color: #5d309b;
    &.active {
        box-shadow: 0px 8px 15px 5px rgb(93 48 155 / 65%)
    }
}

.cardPremiumContainer {
    background-color: #88227c;
    &.active {
        box-shadow: 0px 8px 15px 5px rgb(134 34 126 / 65%)
    }
}

.cardCustomContainer {
    background: #FC4E27;
    &.active {
        box-shadow: 0px 8px 15px 5px rgba(252, 78, 39, 0.6)
    }
}