.bodyContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 20px;
    margin: 10px;
    height: calc(100% - 73px);
    overflow: auto;
}

.formSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sourceRowInputs {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.tablesWrap {
    display: flex;
    justify-content: space-between;
    gap: 40px
}

.tableContainer {
    padding: 16px 24px;
    /* background: #313AB8; */
    border-radius: 4px;
    width: 100%;
}

.tablesWrap .tableContainer table th,
.tablesWrap .tableContainer table td {
padding: 10px;
/* color: white; */
}

.tableContainer tr td:last-child {
    font-weight: bold;
}

.nextContainer {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    padding: 30px 0;
}

.linkContainer {
    background-image: linear-gradient(to right, #F30531 0%, #313AB8  51%, #F30531  100%);
    border: none;
    color: #fff;
    transition: 0.8s;
    background-size: 200% auto;
    width: 100%;
    padding: 15px 30px;
    text-align: center;
    border-radius: 4px;
}

.linkContainer:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff!important;
    text-decoration: none;
}

.linkContainer span {
    font-size: 16px;
    font-weight: bold;
}