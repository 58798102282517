.leaflet-container {
  background-color: #34363B!important;
}

.leaflet-control-attribution {
  display: none!important;
}

.pm-text-marker .pm-textarea {
  padding-left: 4px!important;
  padding-top: 3px!important;
}

.pm-text-marker .pm-textarea.pm-disabled {
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  height: 20px!important;
  width: 20px!important;
  text-align: center;
  padding: 0px 3px!important;
}

.actions-tooltip-container {
  height: 175px;
  width: 130px;
}

.leaflet-marker-pane .marker-placeholder {
  display: none;
}

.leaflet-tooltip {
  padding: 0px;
  opacity: 1!important;

  &.area-tooltip {
    padding: 6px;
  }
  &.leaflet-tooltip-right:before {
    top: 10%;
  }
}