$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }



.tabContainer { 
    display: flex;
    padding: 10px;
    width: 100%;
    height: 100%;
    flex: 1;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;    
}

.desktopLayout {
    @include for-phone-only {
        display: none;
                }
                @include for-tablet-portrait-up {
                    display: none;
                }
                @include for-tablet-landscape-up {
                    display: none;
                }
                @include for-desktop-up {
                    display: flex;
                }
                @include for-big-desktop-up {
                    display: flex;
                } 
}

.mobileLayout {
    @include for-phone-only {
        display: flex;
                }
                @include for-tablet-portrait-up {
                    display: flex;
                }
                @include for-tablet-landscape-up {
                    display: flex;
                }
                @include for-desktop-up {
                    display: none;
                }
                @include for-big-desktop-up {
                    display: none;
                } 
}

.mobileBtn {
    @include for-phone-only {
display: flex;
    }
    @include for-tablet-portrait-up {
        display: flex;
    }
    @include for-tablet-landscape-up {
        display: flex;
    }
    @include for-desktop-up {
        display: none;
    }
    @include for-big-desktop-up {
        display: none;
    }

}

.btnWrap {
    display: flex;
    gap: 10px;
}

.dropdownMenu {

    ul {
        border-radius: 2px;
        padding: 4px 0 4px 0;

        li {
            border-radius: 0px!important;
        }
    }

    li:hover {
        background-color: #E6F7FF!important;
        
        span {
            color: #1890FF!important;
        }
    }

    .dropdownMenuItem {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}

h3 {
    font-size: 16px;
    line-height: 1.571;
    margin: 0;
}

.table {
    tBody {
        tr {
            td:first-of-type{
                // color: #313AB8;
            }
        }

        .hiddenRow {
            display: none;
        }
    }

    .tableFooter {
        background-color: #202491;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.571;
    }
}

.mobileTabs {
    background: white;
    width: 100%;
    padding: 0 20px;

}

.mobileTabs > .ant-tabs-nav {
    margin: 0px !important;
}

.mobileSummary {
    @include for-phone-only {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        gap: 15px;
    }
    @include for-tablet-portrait-up {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }
    @include for-tablet-landscape-up {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }
    @include for-desktop-up {
        display: none;
    }
    @include for-big-desktop-up {
        display: none;
    }


}

.totalSummaryContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    padding-top: 28px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-top: none;
    background-color: #fff;

    @include for-phone-only {
        display: none;
    }
    @include for-tablet-portrait-up {
        display: none;
    }
    @include for-tablet-landscape-up {
        display: none;
    }
    @include for-desktop-up {
        display: flex;
    }
    @include for-big-desktop-up {
        display: flex;
    }

    .headContainer {
        display: flex;
        gap: 20px;
        margin-bottom: 8px;
        >div {
            display: flex;
            gap: 12px;
        }

        &.pricingHead {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .otherPricingContainer {
            display: flex;
            flex-direction: column;

            >div {
                display: grid;
                grid-template-columns: 185px 185px 185px;

                >span:first-of-type {
                    color: #313AB8;
                    ;
                }
            }
        }
    }



    .bodyContainer {
        display: flex;
        gap: 10px;

        // @include for-phone-only {
        //     display: flex;
        //     gap: 10px;
        //     flex-direction: column;
        //     flex-wrap: wrap;
        //     align-items: center;
        // }
        // @include for-tablet-portrait-up {
        //     display: flex;
        //     gap: 10px;
        //     flex-direction: column;
        //     flex-wrap: wrap;
        //     align-items: center;
        // }
        // @include for-tablet-landscape-up {
        //     display: flex;
        //     gap: 10px;
        //     flex-wrap: wrap;
        //     flex-direction: row;
        // }
        // @include for-desktop-up {
        //     display: flex;
        //     gap: 10px;
        //     flex-wrap: wrap;
        //     flex-direction: row;
        // }
        // @include for-big-desktop-up {
        //     display: flex;
        //     gap: 10px;
        //     flex-wrap: wrap;
        //     flex-direction: row;
        // }

        >div {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 8px;
            // @include for-phone-only {
            //     max-width: 100%;
            // }
            // @include for-tablet-portrait-up {
            //     max-width: 100%;
            // }
            // @include for-tablet-landscape-up {
            //     max-width: 33.333333%;
            // }
            // @include for-desktop-up {
            //     max-width: 33.333333%;
            // }
            // @include for-big-desktop-up {
            //     max-width: 33.333333%;
            // }
        }

        h2 {
            margin-top: 0px;
        }

        .customBenchmark {
            h3 {
                span {
                    color: #52C41A;
                }
            }
        }

        .cardContainer {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            min-height: 165px;
            // max-width: 33.333333%;
            gap: 10px;
            background-color: #313AB8;
            color: #fff;
            padding: 20px;
            border-radius: 6px;
            -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);

            
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.571;
                margin: 0;
            }

            .cardPrice {
                font-size: 32px;
                font-weight: bold;
            }
            .cardPeriod {
                font-size: 14px;
            }
            .cardDescription {
                font-size: 14px;
            }
        }

        :hover {
            cursor: pointer;
        }

        .cardBasicContainer {
            background-color: #313AB8;
            &.active {
                box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
            }
        }

        .cardOptimalContainer {
            background-color: #5d309b;  
            &.active {
                box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
            }          
        }

        .cardPremiumContainer {
            background-color: #88227c;   
            &.active {
                box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
            }         
        }

        .cardCustomContainer {
            background: #FC4E27;
            &.active {
                box-shadow: 0px 10px 20px 5px rgba(252, 78, 39, 0.6)
            }
        }

        .table {

            .activeBasic {
                background-color: #5d309b20!important;
                color: #5d309b;
            }
            .activeOptimal {
                background-color: #5d309b30!important;
                color: #5d309b;
            }
            .activePremium {
                background-color: #5d309b40!important;
                color: #5d309b;
            }
            .activeCustom {
                background-color: #5d309b50!important;
                color: #5d309b;
            }

            thead tr th {
                background-color: #FFFFFF;
                color: #5d309b!important;
                border-bottom: 2px solid #5d309b;
                font-weight: 700;

                &::before {
                    display: none;
                }
            }     
        }
    }
}

.mobileHeadContainer {
    display: flex;
    margin-bottom: 15px;

    div {
        display: flex;
        gap: 10px;
    }
}

.mobileCardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.cleaningServicesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .bodyContainer {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        >div {
            display: flex;
            flex-direction: column;
            flex: 1;
            min-width: calc(50% - 8px);
            
            .tableHead {
                display: flex;
                justify-content: space-between;
                background-color: #202491;
                color: #ffffff;
                padding: 20px;
                border-radius: 2px 2px 0px 0px;
                >div {
                    cursor: pointer;
                    span {
                        margin-left: 12px;
                    }
                }
            }
        }        
    }
}

.costPerSqFtContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    padding-top: 28px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-top: none;
    background-color: #fff;

    @include for-phone-only {
        display: none;
    }
    @include for-tablet-portrait-up {
        display: none;
    }
    @include for-tablet-landscape-up {
        display: none;
    }
    @include for-desktop-up {
        display: flex;
    }
    @include for-big-desktop-up {
        display: flex;
    }

    .headContainer {
        display: flex;
        gap: 20px;
        margin-bottom: 8px;
        >div {
            display: flex;
            gap: 12px;
        }

        &.pricingHead {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .otherPricingContainer {
            display: flex;
            flex-direction: column;

            >div {
                display: grid;
                grid-template-columns: 185px 185px 185px;

                >span:first-of-type {
                    color: #313AB8;
                    ;
                }
            }
        }
    }

    .bodyContainer {
        display: flex;
        gap: 10px;

        >div {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 8px;   
        }

        .customBenchmark {
            h3 {
                span {
                    color: #52C41A;
                }
            }
        }

        .cardContainer {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            min-height: 165px;
            max-width: 25%;
            gap: 10px;
            background-color: #313AB8;
            color: #fff;
            padding: 20px;
            border-radius: 6px;
            -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.571;
                margin: 0;
            }

            .cardPrice {
                font-size: 32px;
                font-weight: bold;
            }
            .cardPeriod {
                font-size: 14px;
            }
            .cardDescription {
                font-size: 14px;
            }
        }

        .cardBasicContainer {
            background-color: #313AB8;
        }

        .cardOptimalContainer {
            background-color: #5d309b;
        }

        .cardPremiumContainer {
            background-color: #88227c;
        }

        .cardCustomContainer {
            background: #FC4E27;
            &.active {
                box-shadow: 0px 10px 20px 5px rgba(252, 78, 39, 0.6)
            }
        }

        .table {
        
            thead tr th {
                background-color: #FFFFFF;
                color: #5d309b;
                border-bottom: 2px solid #5d309b;
                font-weight: 700;

                &::before {
                    display: none;
                }
            }
        }
    }

    .infoContainer {
        display: flex;
        gap: 12px;
        padding: 2px 8px;
        background-color: #E6F7FF;
        border-radius: 25px;
        height: 36px;
        align-items: center;
        justify-content: center;
        max-width: 384px;

        font-size: 12px;
        line-height: 32px;
        .icon {
            font-size: 21px;
            color: #1890FF;
        }
    }

    .table {
        thead tr {
            th {
                background-color: #FFFFFF;
                color: #5d309b;
                border-bottom: 2px solid #5d309b;
                font-weight: 700;
    
                &::before {
                    display: none;
                }
            }
            th:last-of-type {
                background-color: #5d309b;
                color: #ffffff;
            }           
        }
        tbody tr {
            td:last-of-type {
                background-color: #5d309b;
                color: #ffffff;
            }
            td:last-of-type:hover {
                color: #5d309b;
            }
        }
        tbody tr:hover {
            td:last-of-type {
                color: #5d309b;
            }
        }
    }
}

.mobileCostPerSqFt {
    @include for-phone-only {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        gap: 15px;
    }
    @include for-tablet-portrait-up {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }
    @include for-tablet-landscape-up {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
    }
    @include for-desktop-up {
        display: none;
    }
    @include for-big-desktop-up {
        display: none;
    }
}

.modalContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 24px 38px;
    margin: 0px -38px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.columnContainer {
        flex-direction: column!important;
        gap: 16px;

        .inputContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                width: calc(100% - 90px);
            }

            input {
                width: 90px;
            }
        }
    }

    .headerCell {
        white-space: nowrap;
    }
}

.modalFooterContainer {
    display: flex;
    justify-content: space-between;

    .infoContainer {
        display: flex;
        gap: 10px;
        padding: 2px 12px;
        background-color: #E6F7FF;
        border-radius: 25px;
        height: 36px;
        align-items: center;
        justify-content: center;
        max-width: 384px;

        font-size: 10px;
        line-height: 16px;

        .icon {
            font-size: 21px;
            color: #1890FF;
        }
    }

    >div:last-of-type {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
    }
}


