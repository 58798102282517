.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 20px;
    background-color: white;
    margin: 10px;
    border-radius: 2px;
}

.title {
    display: flex;
    align-items: baseline;
    gap: 12px
}

.title_and_btns {
    padding: 10px 20px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title_and_breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}