.drawerContainer {
  .drawerClose {    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    background-color: #1890FF;
    color: #ffffff;
    border-radius: 2px;

    button {
      margin-right: 0px;
    }
  }

  .drawerList {
    list-style: none;
    padding: 0px;
    margin: 10px 0px;
    li {
      display:flex;
      align-items: center;
      border-bottom: 1px solid #D5DAEB;
      padding: 8px 16px;
      gap: 5px;
      cursor: pointer;
      span {
        font-size: 14px;
        line-height: 1.571;

        &.colorCircle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }      
    }
    li:last-of-type {
      border-bottom: none;
    }
  }
}

.actionsModal {
  width: auto;
  height: 165px;
  border-radius: 2px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column; 
  
  &.virtualWalk {
    height: 165px;
  }
  
  >span{
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 5px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.57;
  }
  >div {
    padding: 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    

    >div {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 40px;    
      padding: 0px 16px;  
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    span {
      font-size: 14px;
      line-height: 1.57;
    }
  }
}


.topControls {
  position: relative;
  top: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  height: 0px;
  width: 100%;
  left: 0;

  transition: left 0.3s, width 0.3s;

  button {
    color: #202491;
  }

  &.drawerOpen {
    width: calc(100% - 275px);
    left: 275px;
    position: absolute;

    transition: left 0.3s, width 0.3s;
  }

  .activeButton {
    color: #096DD9;
    border-color: #096DD9
  }

    >div {
        display: flex;
        gap: 20px;
        flex: 1;
        margin-left: 60px;

        button span {
          margin-right: 8px;
        }
    }

    >div:last-of-type {
        justify-content: flex-end;
        margin-left: 0px;
        margin-right: 20px;
    }

    .dropdownMenu {
      ul {
          border-radius: 8px;
          padding: 4px 0 4px 0;
  
          li {
              border-radius: 0px!important;
          }
      }
  
      li:hover {
          background-color: #E6F7FF!important;
          
          span {
              color: #1890FF!important;
          }
      }
  
      .dropdownMenuItem {
          display: flex;
          gap: 10px;
          align-items: center;
      }
  }

  .switchContainer {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    height: 32px;
    padding: 0px 15px;
  }
}

.wrapper {
  height: calc(100vh - 275px);
  min-height: calc(90vh);
  border-radius: 8px;
  background-color: var(--dark-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.minified {
    height: calc(100vh - 200px)!important;
    min-height: calc(100vh - 200px);
  }
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.planContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leafletContainer {
  width: 100%;
  height: 100%;
}

.modalRow {
  width: 100%;

  >div {
    width: 50%;

    input {
      width: 100%;
    }
  }
}

.itemsModal {
  width: 100%!important;
  max-width: 1500px;
  height: 100%;
  max-height: 75vh;
}

.itemsModalHead {
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modalContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-height: 70vh;

  &.columnContainer {
      flex-direction: column!important;
      gap: 16px;

      .inputContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
              width: 90px;
          }
      }
  }

  >div:last-of-type {
    height: calc(70vh - 175px);
    overflow: hidden auto;
  }

  .headerCell {
      white-space: nowrap;
  }

  .tableHeader {
    display: flex;
    flex-direction: column;
    width: 100%;


    // TODO OLD table header
    // >div:first-of-type {
    //     display: flex;
    //     background-color: #202491;
    //     border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    //     color: #ffffff;
    //     padding: 8px 0px;
    //     width: 100%;

    //     div:nth-of-type(1) {
    //       width: 10%
    //     }
  
    //     div:nth-of-type(2) {
    //       width: 3%
    //     }
    //     div:nth-of-type(3) {
    //       width: 21%
    //     }
    //     div:nth-of-type(n+4):nth-of-type(-n+6) {
    //       width: 5%
    //     }
    //     div:nth-of-type(7) {
    //       width: 21%
    //     }
  
    //     div:nth-of-type(n+8):nth-of-type(-n+12) {
    //       width: 6%
    //     }
  
    //     >div:nth-of-type(3), >div:nth-of-type(7) {
    //       border-left: 1px solid rgba(255, 255, 255, 0.5);
    //       box-sizing: border-box;
    //     }
        
    //     .gridItem {
    //         padding: 0px 8px;
    //         font-weight: 600;
    //         font-size: 14px;
    //         line-height: 1.57;
    //     }
    // }

    // >div:last-of-type {
    //   width: 100%;
    //   display: flex;
    //   background-color: #5460C4;
    //   border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    //   color: #ffffff;
    //   padding: 8px 0px;
    //   >div {
    //     padding: 0px 8px;
    //   }

    //   div:nth-of-type(1), div:nth-of-type(7) {
    //     width: 3%
    //   }
    //   div:nth-of-type(2) {
    //     width: 10%
    //   }

    //   div:nth-of-type(n+3):nth-of-type(-n+6) {
    //     width: 9%
    //   }

    //   div:nth-of-type(n+8):nth-of-type(-n+11) {
    //     width: 9%
    //   }
    //   div:nth-of-type(12) {
    //     width: 12%
    //   }

    //   >div:nth-of-type(3), >div:nth-of-type(7) {
    //     border-left: 1px solid rgba(255, 255, 255, 0.5);
    //     box-sizing: border-box;
    //   }
    // }

    >div:first-of-type {
      display: flex;
      background-color: #202491;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      color: #ffffff;
      padding: 8px 0px;
      width: 100%;

      div:nth-of-type(1) {
        width: 40%
      }     
      div:nth-of-type(n+2):nth-of-type(-n+5) {
        width: 15%
      }

      >div:nth-of-type(2) {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
      }
      
      .gridItem {
          padding: 0px 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.57;
      }
  }

  >div:last-of-type {
    width: 100%;
    display: flex;
    background-color: #5460C4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: #ffffff;
    padding: 8px 0px;
    >div {
      padding: 0px 8px;
    }

    div:nth-of-type(1) {
      width: 40%
    }

    div:nth-of-type(n+2):nth-of-type(-n+5) {
      width: 15%
    }

    >div:nth-of-type(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
    }
  }
  }

  .itemsGroupedTable table tbody tr.newItem {
    background: linear-gradient(90deg, #ffffff, #ffffff, #b1b7e4);
    background-size: 200% 200%;
    animation: blinking 3s linear;
    animation-duration: 2s;
  }
  @keyframes blinking {    
    0%{
      background-position-x: 200%
    }
    100%{
      background-position-x: 0%
    }
  }
}

.modalFooterContainer {
  display: flex;
  justify-content: space-between;

  >div:last-of-type {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
  }
}