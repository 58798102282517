$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.headContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &.mainHead {

        height: 48px;
        padding: 8px 20px;

        .shareButton {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
    }

    .shareButton {
      @include for-phone-only {
        display: none;
              }
              @include for-tablet-portrait-up {
                display: none;
              }
              @include for-tablet-landscape-up {
                display: none;
              }
              @include for-desktop-up {
                display: flex;
              }
              @include for-big-desktop-up {
                display: flex;
              }
    }

    .shareButtonSmall {
      display: none;
      @include for-phone-only {
display: block;
      }
      @include for-tablet-portrait-up {
        display: block;
      }
      @include for-tablet-landscape-up {
        display: block;
      }
      @include for-desktop-up {
        display: none;
      }
      @include for-big-desktop-up {
        display: none;
      }
    }

    
    &.subHead {
        height: 64px;
        padding: 20px 40px;

        @include for-phone-only {
            padding: 10px 20px;
            border-bottom: 1px solid lightgray;
          }
          @include for-tablet-portrait-up {
            padding: 10px 20px;
            border-bottom: 1px solid lightgray;
          }
          @include for-tablet-landscape-up {
            padding: 15px 30px;
            border-bottom: 1px solid lightgray;
          }
          @include for-desktop-up {
            padding: 20px 40px;
          }
          @include for-big-desktop-up {
            padding: 20px 40px;
          }

        .pageTitle {
            span {
                color:rgba(0, 0, 0, 0.65);
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;

                @include for-phone-only {
                    font-size: 14px;
                  }
                  @include for-tablet-portrait-up {
                    font-size: 16px;
                  }
                  @include for-tablet-landscape-up {
                    font-size: 16px;
                  }
                  @include for-desktop-up {
                    font-size: 20px;
                  }
                  @include for-big-desktop-up {
                    font-size: 20px;
                  }

                &.pageNumber {
                    font-size: 24px;

                    @include for-phone-only {
                        font-size: 14px;
                      }
                      @include for-tablet-portrait-up {
                        font-size: 16px;
                      }
                      @include for-tablet-landscape-up {
                        font-size: 16px;
                      }
                      @include for-desktop-up {
                        font-size: 24px;
                      }
                      @include for-big-desktop-up {
                        font-size: 24px;
                      }
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 24px;
            gap: 10px;

            .display {
                min-width: 67px;
                height: 24px;
                padding: 1px 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #FFFFFF;
                border: 1px solid rgba(49, 58, 184, 0.15);
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
                border-radius: 2px;
                gap: 7px;
                user-select: none;

                @include for-phone-only {
                    display: none;
                  }
                  @include for-tablet-portrait-up {
                    display: none;
                  }
                  @include for-tablet-landscape-up {
                    display: none;
                  }
                  @include for-desktop-up {
                    display: flex;
                  }
                  @include for-big-desktop-up {
                    display: flex;
                  }
            }

            .button  {
                display: flex;
                justify-content: center;
                align-items: center;
                // background-color: #313AB8;
                background: linear-gradient(to right, #313ab8 30%, #88227c  100%);
                color: white;
                width: 24px;
                height: 24px;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
                border-radius: 2px;
                cursor: pointer;

                &.disabled {
                    opacity: 0.5;
                    cursor: unset;
                }
            }
        }
    }
}

.footContainer {
    background-color: #E9ECF7;
    padding: 13px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px
}

.modalContainer {
    .modalRow {
        display: flex;
        width: 100%;
        gap: 8px;

        input {
            box-shadow: none;
        }

        button {
            min-width: 70px;
        }
    }

    ul {
        width: calc(100% - 100px);
        padding-left: 20px;

        li {
            div {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
