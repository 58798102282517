.loginWrap {
background: url(../../img/loginBackground.png) no-repeat center center;
background-size: cover;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.textAndButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.titleText {
    text-align: center;
}

.coloredButton {
    background-image: linear-gradient(to right, #F30531 0%, #313AB8  51%, #F30531  100%);
    border: none;
    color: #fff;
    transition: 0.8s;
    background-size: 200% auto;
}

.coloredButton:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff!important;
    text-decoration: none;
}

.loginModal {
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 16px 24px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
}

.loginInputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.loginImage {
    width: 80%!important;
}
  

@media only screen and (max-width: 599px) {
.loginImage {
    width: 320px!important;
}
}

@media only screen and (min-width: 600px) {
    .loginImage {
        width: 500px!important;
    }
    }

@media only screen and (min-width: 900px) {
    .loginImage {
        width: 500px!important;
    }
    }

@media only screen and (min-width: 1200px) {
    .loginImage {
        width: 500px!important;
    }
    }

@media only screen and (min-width: 1800px) {
    .loginImage {
        width: 500px!important;
    }
    }

 
        
 