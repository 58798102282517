$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.container {
    display: flex;
    align-items: center;
    height: calc(100vh - 64px);
    gap: 10px;
    padding: 40px 20px;
    background-image: url("../../../../img/benchmark_bg.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: cover;
    background-color: #130F21;

    .cardContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        height: 100%;
        align-content: center;
        @include for-phone-only {
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            padding: 20px;

        }
        @include for-tablet-portrait-up {
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            padding: 20px;
        }
        @include for-tablet-landscape-up {
            flex-direction: row;
            flex-wrap: wrap;
        }
        @include for-desktop-up {
            flex-direction: row;
            flex-wrap: wrap;
        }
        @include for-big-desktop-up {
            flex-direction: row;
            flex-wrap: wrap;
        }
    

    .cardItem {
        max-width: 50%;
        width: calc(50% - 8px);
        // height: 150px;
        background: linear-gradient(to bottom,#88227c 1%,#313ab8 100%,#7db9e8 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        padding: 16px;
        gap: 10px;
        @include for-phone-only {
            flex-direction: column;
            align-items: center;
            height: auto;
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 30px 20px;
        }
        @include for-tablet-portrait-up {
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 30px 20px;
        }
        @include for-tablet-landscape-up {
            flex-direction: row;
            max-width: 50%;
            width: calc(50% - 8px);
            height: 150px;
            text-align: left;
            padding: 16px;
        }
        @include for-desktop-up {
            flex-direction: row;
            max-width: 50%;
            width: calc(50% - 8px);
            height: 150px;
            text-align: left;
            padding: 16px;
        }
        @include for-big-desktop-up {
            flex-direction: row;
            max-width: 50%;
            width: calc(50% - 8px);
            height: 150px;
            text-align: left;
            padding: 16px;
        }

        .cardIcon {
            width: 100px;
            height: 100px;
            // border: 6px solid #FFFFFF;
            // border-radius: 50%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-direction: column;
            // position: relative;
            // >div {
            //     position: absolute;
            //     top: -10px;
            //     background-color: #FFFFFF;
            //     border-radius: 20px;
            //     min-width: 22px;
            //     height: 22px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
                
            //     span {
            //         line-height: 20px;
            //         font-size: 16px;
            //         text-align: center;
            //         font-weight: 600;
            //     }
            // }
        }

        .cardContent {
            display: flex;
            flex-direction: column;
            max-width: 60%;
            gap: 10px;
            margin-left: 20px;
            color: #FFFFFF;
            @include for-phone-only {
                flex-direction: column;
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                
            }
            @include for-tablet-portrait-up {
                flex-direction: column;
                width: 100%;
                max-width: 100%;
                margin-left: 0;
            }
            @include for-tablet-landscape-up {
                flex-direction: column;
            }
            @include for-desktop-up {
                flex-direction: column;
            }
            @include for-big-desktop-up {
                flex-direction: column;
            }

            .cardTitle {
                font-size: 18px;
                font-weight: 700;
            }

            .cardDescription {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }    
}
}