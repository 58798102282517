$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-radius: 6px;
  background-image: url("../../../../img/benchmark_bg.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center right;
  background-size: cover;
  background-color: #130f21;

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 70px 130px;
    justify-content: space-around;
    align-items: flex-start;
    @include for-phone-only {
        padding: 40px 40px;
        flex-direction: column;
    }
    @include for-tablet-portrait-up {
        padding: 40px 40px;
        flex-direction: column;
    }
    @include for-tablet-landscape-up {
        padding: 60px 80px;
        flex-direction: row;
    }
    @include for-desktop-up {
        padding: 60px 110px;
        flex-direction: row;
    }
    @include for-big-desktop-up {
        padding: 70px 130px;
        flex-direction: row;
    }

    .contentContainer {
      max-width: 400px;

      .sqBorder {
        width: 100px !important;
        min-width: unset !important;
        height: 5px;
        background: linear-gradient(90deg, #313ab8 0%, #f30531 100%);
        border-radius: 25px;
      }

      > p {
        color: #ffffff;
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        @include for-phone-only {
            font-size: 14px;
        }
        @include for-tablet-portrait-up {
            font-size: 16px;
        }
        @include for-tablet-landscape-up {
            font-size: 18px;
        }
        @include for-desktop-up {
            font-size: 20px;
        }
        @include for-big-desktop-up {
            font-size: 22px;
        }
      }

      .contentList {
        // background-color: #FFFFFF;
        padding: 10px;
        border-radius: 4px;
        color: #ffffff;

        h2 {
            @include for-phone-only {
                font-size: 12px;
            }
            @include for-tablet-portrait-up {
                font-size: 14px;
            }
            @include for-tablet-landscape-up {
                font-size: 16px;
            }
            @include for-desktop-up {
                font-size: 18px;
            }
            @include for-big-desktop-up {
                font-size: 20px;
            }
        }

        span {
          font-size: 22px;
          line-height: 32px;
          @include for-phone-only {
            font-size: 14px;
        }
        @include for-tablet-portrait-up {
            font-size: 16px;
        }
        @include for-tablet-landscape-up {
            font-size: 18px;
        }
        @include for-desktop-up {
            font-size: 20px;
        }
        @include for-big-desktop-up {
            font-size: 22px;
        }
        }

        ul {
          padding-left: 0px;
          li {
            font-size: 18px;
            line-height: 28px;
            padding-left: 5px;
            list-style: none;
            @include for-phone-only {
                font-size: 12px;
            }
            @include for-tablet-portrait-up {
                font-size: 14px;
            }
            @include for-tablet-landscape-up {
                font-size: 16px;
            }
            @include for-desktop-up {
                font-size: 16px;
            }
            @include for-big-desktop-up {
                font-size: 18px;
            }
          }
        }
      }

      .listItemIcon > svg {
        color: #f2ce00;
        margin-right: 5px;
      }
    }
  }
}
