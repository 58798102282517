.room-mapping-container.site-drawer-render-in-current-wrapper {
    // height: calc(100vh - 400px);
    position: relative;
    overflow: hidden;
    text-align: left;
    background: #ffffff;

    .ant-drawer.ant-drawer-left {
        max-width: 275px;
        width: 100%;

        .ant-drawer-content-wrapper {
            max-width: 275px;
            width: 100%;

            .ant-drawer-header {
                padding: 10px!important;
                border-bottom: 0!important;
            
                .ant-drawer-header-title {
                    flex-direction: row-reverse;
            
                    .ant-drawer-title {
                        font-size: 20px;
                        line-height: 1.57;
                    }
                    button {
                        margin-right: 0px!important;
                        padding: 0px!important;
                    }
                }
            }
            .ant-drawer-body {
                padding: 0px 10px!important;
            }
        }
    }
}

.items-grouped-table {

    .ant-select .ant-select-selector {
        box-shadow: none!important;
    }

    tr.ant-table-row-level-1 td:first-of-type {
        >span {
            display: none;
        }
        >button {
            display: none;
        }
        >div {
            display: flex;
            justify-content: flex-end;
        }
    }
}