.bodyContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    padding: 20px;
    margin: 10px;
    height: calc(100% - 73px);
}

.formSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-left: 3px solid #313AB8;
}

.formWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}