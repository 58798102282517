$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }


h3 {
    font-size: 16px;
    line-height: 1.571;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 28px 20px;
    gap: 16px;
    background-color: #fff;
    margin: 10px;
    min-height: 90vh;

    @include for-phone-only {
        display: none;
                }
                @include for-tablet-portrait-up {
                    display: none;
                }
                @include for-tablet-landscape-up {
                    display: none;
                }
                @include for-desktop-up {
                    display: flex;
                }
                @include for-big-desktop-up {
                    display: flex;
                } 


    .tableContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .headContainer {
        display: flex;
        gap: 20px;
        margin-bottom: 8px;
        >div {
            display: flex;
            gap: 12px;
        }

        &.pricingHead {
            flex-direction: column;
            margin-bottom: 0px;
        }

        .otherPricingContainer {
            display: flex;
            flex-direction: column;

            >div {
                display: grid;
                grid-template-columns: 185px 185px 185px;

                >span:first-of-type {
                    color: #313AB8;
                    ;
                }
            }
        }
    }

    .bodyContainer {
        display: flex;
        gap: 10px;

        >div {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 8px;   
        }

        .customBenchmark {
            h3 {
                span {
                    color: #52C41A;
                }
            }
        }

        .cardContainer {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            max-width: 25%;
            gap: 10px;
            background-color: #313AB8;
            color: #fff;
            padding: 20px;
            border-radius: 6px;
            -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
            
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.571;
                margin: 0;
            }

            .cardPrice {
                font-size: 32px;
                font-weight: bold;
            }
            .cardPeriod {
                font-size: 14px;
            }
            .cardDescription {
                font-size: 14px;
            }
        }

        :hover {
            cursor: pointer;
        }

        .cardBasicContainer {
            background-color: #313AB8;

            &.active {
                box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
            }
        }

        .cardOptimalContainer {
            background-color: #5d309b;
            &.active {
                box-shadow: 0px 15px 15px 5px rgb(134 34 126 / 65%)
            }
        }

        .cardPremiumContainer {
            background-color: #88227c;
            &.active {
                box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
            }
        }

        .cardCustomContainer {
            background: #FC4E27;
            &.active {
                box-shadow: 0px 10px 20px 5px rgba(252, 78, 39, 0.6)
            }
        }
    }

    .table {
        .activeBasic {
            background-color: #5d309b20!important;
            color: #5d309b;
        }
        .activeOptimal {
            background-color: #5d309b30!important;
            color: #5d309b;
        }
        .activePremium {
            background-color: #5d309b40!important;
            color: #5d309b;
        }
        .activeCustom {
            background-color: #5d309b50!important;
            color: #5d309b;
        }

        thead tr th {
            background-color: #FFFFFF;
            color: #5d309b;
            border-bottom: 2px solid #5d309b;
            font-weight: 700;

            &::before {
                display: none;
            }
        }
    }
}

.mobilePeriodicClening {

    @include for-phone-only {
        display: flex;
        flex-direction: column;
        padding: 15px 10px;
        gap: 16px;
        background-color: #fff;
        margin: 10px;
        min-height: 90vh;
                }
                @include for-tablet-portrait-up {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 20px;
                    gap: 16px;
                    background-color: #fff;
                    margin: 10px;
                    min-height: 90vh;
                }
                @include for-tablet-landscape-up {
                    display: flex;
                    flex-direction: column;
                    padding: 28px 20px;
                    gap: 16px;
                    background-color: #fff;
                    margin: 10px;
                    min-height: 90vh;
                }
                @include for-desktop-up {
                    display: none;
                }
                @include for-big-desktop-up {
                    display: none;
                } 
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;    
}