$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

.bodyContainer {
    display: flex;
    gap: 10px;

    @include for-phone-only {
        display: none!important;
                }
                @include for-tablet-portrait-up {
                    display: none!important;
                }
                @include for-tablet-landscape-up {
                    display: none!important;
                }
                @include for-desktop-up {
                    display: flex!important;
                }
                @include for-big-desktop-up {
                    display: flex!important;
                } 

    >div {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;   
    }

    .customBenchmark {
        h3 {
            span {
                color: #52C41A;
            }
        }
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        max-width: 33.333333%;
        gap: 10px;
        background-color: #313AB8;
        color: #fff;
        padding: 20px;
        border-radius: 6px;
        -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
        -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
        box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.36);
        
        h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.571;
            margin: 0;
        }

        .cardPrice {
            font-size: 32px;
            font-weight: bold;
        }
        .cardPeriod {
            font-size: 14px;
        }
        .cardDescription {
            font-size: 14px;
        }
    }

    :hover {
        cursor: pointer;
    }

    .cardBasicContainer {
        background-color: #313AB8;
        &.active {
            box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
        }
    }

    .cardOptimalContainer {
        background-color: #5d309b;
        &.active {
            box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
        }
    }

    .cardPremiumContainer {
        background-color: #88227c;
        &.active {
            box-shadow: 0px 15px 30px 5px rgb(134 34 126 / 65%)
        }
    }

    .cardCustomContainer {
        background: #FC4E27;
        &.active {
            box-shadow: 0px 10px 20px 5px rgba(252, 78, 39, 0.6)
        }
    }
}

.table {
    .activeBasic {
        background-color: #5d309b20!important;
        color: #5d309b!important;
    }
    .activeOptimal {
        background-color: #5d309b30!important;
        color: #5d309b!important;
    }
    .activePremium {
        background-color: #5d309b40!important;
        color: #5d309b!important;
    }
    .activeCustom {
        background-color: #5d309b50!important;
        color: #5d309b!important;
    }
    thead tr {
        th {
            background-color: #FFFFFF!important;
            color: #5d309b!important;
            border-bottom: 2px solid #5d309b!important;
            font-weight: 700!important;

            &::before {
                display: none!important;
            }
        }
    }
}

.mobileTableContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
    @include for-phone-only {
        display: flex!important;
                }
                @include for-tablet-portrait-up {
                    display: flex!important;
                }
                @include for-tablet-landscape-up {
                    display: flex!important;
                }
                @include for-desktop-up {
                    display: none!important;
                }
                @include for-big-desktop-up {
                    display: none!important;
                } 
}