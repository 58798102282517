$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }


.textLogoWhite {
  height: 30px;
  width: auto;
}
  
.sidebarMenuWrap {
  /* height: 85%; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  position: relative;
  height: 80vh;
  /* top: 60px; */
}
  
  .ant-layout-sider-trigger {
    background: var(--primary)!important;
  }
  
  .sectionTitle {
    width: 100%;
    padding: 9px 16px;
  }
  
  .sectionTitle > span {
    color: var(--white);
    text-transform: uppercase;
    font-size: 12px;
    color: var(--font-purple);
    line-height: 20px;
  }
  
  .customMenuList {
    color: rgba(255, 255, 255, 0.65);
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .customMenuItem {
    margin: 4px 0;
    transition: 
      border-color 0.3s,
      background 0.3s,
      padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1),
      cursor pointer
  }
  
  .customMenuItem > div:hover {
    background: rgba(195, 194, 209, 0.2);
  }
  
  .customMenuItem > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 24px;
  }
  
  .menuItemTitle {
    margin-left: 10px;
    line-height: 40px;
    width: 100%;
  }
  
  .arrowIcon svg {
    height: 12px;
    width: 12px;
  }
  
  .openSubmenu {
    width: 20%;
    display: flex;
    justify-content: end;
    padding-right: 24px;
  }
  
  .openSubmenu span {
    line-height: 40px;
  }
  
  .titleWrap {
    display: flex;
    width: 80%;
  }
  
  .customMenuItem ul {
    list-style: none;
  }
  
  .menuItemWrap {
    padding: 0 10px;
  }
  
  .menuItemWrap:hover {
    background-color: rgba(195, 194, 209, 0.2) !important;
    border-radius: 2px;
  }
  
  .sectionTitle {
    width: 100%;
    font-size: 12px;
    padding: 9px 16px;
    color: rgba(255, 255, 255, 0.35)
}
  
.displaySidebar {
  @include for-phone-only {
    display: none;
  }
  @include for-tablet-portrait-up {
    display: none;
  }
  @include for-tablet-landscape-up {
    display: none;
  }
  @include for-desktop-up {
    display: block;
  }
  @include for-big-desktop-up {
    display: block;
  }
}